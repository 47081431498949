export default (productId, quantity) => ({
    productId: productId,
    quantity: quantity ?? 0,
    dirtyState: false,
    increment() {
        this.putProductCard(this.quantity + 1);
    },
    decrement() {
        this.putProductCard(Math.max(this.quantity - 1, 0));
    },
    putUpdate(e) {
        this.putProductCard(e.target.value);
    },
    async putProductCard(quantity) {
        if (quantity === "") {
            return;
        }
        this.dirtyState = true;
        window.axios
            .put(route("api.card-products.update", this.productId), {
                quantity,
            })
            .then(({ data }) => {
                this.quantity = data.quantity;
                this.dirtyState = false;
                this.$store.cart.loadCount();
            })
            .catch(function (error) {
                if (error.response) {
                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2xx
                    console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);
                } else if (error.request) {
                    // The request was made but no response was received
                    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                    // http.ClientRequest in node.js
                    console.log(error.request);
                } else {
                    // Something happened in setting up the request that triggered an Error
                    console.log("Error", error.message);
                }
                console.log(error.config);
            });
    },
});
