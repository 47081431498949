export default (model, id) => ({
    id: id,
    model: model,
    options: [],
    selected: [],
    show: false,
    toggle() {
        if (this.isOpen()) {
            this.close();
        } else {
            this.open();
        }
    },
    open() {
        this.show = true;
    },
    close() {
        this.show = false;
    },
    isOpen() {
        return this.show === true;
    },
    select(index, event) {
        if (!this.options[index].selected) {
            this.options[index].selected = true;
            this.options[index].element = event.target;
            this.selected.push(index);
        } else {
            this.selected.splice(this.selected.lastIndexOf(index), 1);
            this.options[index].selected = false;
        }
        this.$wire.set(this.model, this.selectedValues());
    },
    remove(index, option) {
        this.options[option].selected = false;
        this.selected.splice(index, 1);
        this.$wire.set(this.model, this.selectedValues());
    },
    loadOptions() {
        const options =
            document.getElementById(this.id)?.options ?? this.id.options;
        const selectedOptions = [
            ...(document.getElementById(this.id)?.selectedOptions ??
                this.id.selectedOptions),
        ];

        for (let i = 0; i < options.length; i++) {
            this.options.push({
                value: options[i].value,
                text: options[i].innerText,
                selected: options[i] == selectedOptions[i],
            });
            if (selectedOptions.includes(options[i])) {
                this.selected.push(i);
            }
        }
    },
    selectedValues() {
        return this.selected.map((option) => {
            return this.options[option].value;
        });
    },
});
