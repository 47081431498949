export default (cart, metadata) => ({
    shipping: metadata.shipping ?? "pickup",
    init() {
        this.$store.cart.setCart(cart);
        this.$store.cart.setMetadata(metadata);
        this.$watch("shipping", (value) => {
            this.$store.cart.updateMetadata("shipping", value);
        });
    },
    decrement(productId) {
        this.$store.cart.decrementProduct(productId);
    },
    increment(productId) {
        this.$store.cart.incrementProduct(productId);
    },
    setQuantity(productId, quantity) {
        this.$store.cart.setQuantity(productId, quantity);
    },
    getQuantity(productId) {
        return this.$store.cart.cart[productId].quantity;
    },
    setMetadata(key, value) {
        this.$store.cart.setMetadata(key, value);
    },
    getMetadata(key) {
        return this.$store.cart.metadata[key];
    },
    getTotal() {
        return (
            "€" +
            String((this.$store.cart.getTotal() / 100).toFixed(2)).replace(
                ".",
                ","
            )
        );
    },
    getTotalVat() {
        return (
            "€" +
            String((this.$store.cart.getTotalVat() / 100).toFixed(2)).replace(
                ".",
                ","
            )
        );
    },
    getTotalByProduct(productId) {
        return (
            "€" +
            String(
                (this.$store.cart.getTotalByProduct(productId) / 100).toFixed(2)
            ).replace(".", ",")
        );
    },
    removeProduct(productId) {
        this.$refs["product-" + productId + "-card"].remove();
        this.$store.cart.removeProduct(productId);
    },
});
